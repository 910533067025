import React from 'react';
import PropTypes from 'prop-types';

import { withStyles } from '../tools/withStyles';
import { Main } from '../components/Main';
import { Secuence } from '../components/Secuence';
import { Text } from '../components/Text';
import { Fader } from '../components/Fader';

const styles = (theme) => ({
  root: {},
  main: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap'
  },
  service: {
    width: '100%',
    padding: '20px',
    display: 'block',
    border: '1px solid #fae147',
    textAlign: 'center',
    margin: '10px'
  },
  cover: {
    display: 'block',
    margin: [0, 0, 20],
    width: '100%',

    '& img': {
      margin: 0,
      width: '100%',
      maxWidth: '100%'
    }
  },

  '@media screen and (min-width: 768px)': {
    service: {
      padding: 20,
      width: '47%'
    }
  }
});

class Services extends React.Component {
  static propTypes = {
    classes: PropTypes.object
  };

  render () {
    const { classes } = this.props;

    return (
      <Main className={classes.root}>
        <Secuence stagger>
          <h1 style={{ textAlign: 'center' }}>
            <Text>Services</Text>
          </h1>
          <div className={classes.main}>
			   <div className={classes.service}>
              <Fader className={classes.cover}>

                <h4><Text>Web Development</Text></h4>

                <p>
                  <Text>
                    Take your online presence to the next level with my
                    professional Web Development services.

                    As an experienced developer, I can create dynamic and
                    user-friendly websites using platforms like WordPress and
                    Prestashop.
                  </Text>
                </p>

              </Fader>
            </div>
            <div className={classes.service}>
              <Fader className={classes.cover}>

                <h4><Text>Graphic Design</Text></h4>

                <p>
                  <Text>
                    Transform your brand's image with stunning Graphic Design
                    services.

                    I specialize in using Adobe Photoshop to craft eye-catching
                    logos, captivating marketing materials, and visually appealing
                    designs.
                  </Text>
                </p>

              </Fader>
            </div>
            <div className={classes.service}>
              <Fader className={classes.cover}>

                <h4><Text>Server Administration</Text></h4>

                <p>
                  <Text>
                    Ensure the seamless functioning of your digital infrastructure
                    with my Server Administration expertise.

                    I have the knowledge to handle both Windows and Linux
                    environments, providing optimal performance and security.
                  </Text>
                </p>

              </Fader>
            </div>
            <div className={classes.service}>
              <Fader className={classes.cover}>

                <h4><Text>IT Management</Text></h4>

                <p>
                  <Text>
                    Streamline your IT processes and ensure efficiency with my IT
                    Management solutions.

                    From Hardware and Software management to Network optimization,
                    I've got you covered.
                  </Text>
                </p>

              </Fader>
            </div>

            <Fader className={classes.cover}>

              <p style={{ textAlign: 'center' }}>
                <Text>
                  I'm committed to delivering high-quality services that meet
                  your unique needs.

                  I take pride in providing personalized attention to every
                  project.

                  Let's work together to achieve your goals.</Text></p>
            </Fader>

          </div>
        </Secuence>
      </Main>
    );
  }
}

export default withStyles(styles)(Services);
